import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import { setUserSession } from "../../utils/Common";
import LOGO from "../assets/ashley.png";
import BG2 from "../assets/login.jpg";

function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required";
    if (!password) newErrors.password = "Password is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [email, password]);

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      if (!validateForm()) return;

      setLoading(true);
      try {
        const { data } = await axios.post(
          "/api/login",
          { onEmail: email, onPassword: password },
          { headers }
        );

        setLoading(false);
        if (data.status === 200) {
          setUserSession(data.token, data.user);
          history.push("/dashboard");
        } else {
          Swal.fire({
            icon: "error",
            text: "Invalid email or password.",
          });
        }
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: "Email or Password is incorrect.",
        });
      }
    },
    [email, password, history, validateForm]
  );

  useEffect(() => {
    const root = document.getElementById("root");
    root.style.height = "100%";
    root.style.background = `center / cover no-repeat url(${BG2})`;
  }, []);

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="col-lg-4 col-md-6">
            <div className="card mb-3 shadow-lg" style={cardStyles}>
              <div className="card-body">
                <div className="d-flex justify-content-center py-3">
                  <img src={LOGO} alt="Ashley Hotel" style={{ width: "120px" }} />
                </div>
                <form onSubmit={handleLogin} className="row g-3">
                  <div className="col-12">
                    <label htmlFor="email" className="form-label" style={labelStyles}>
                      Email
                    </label>
                    <input
                      type="email"
                      className={`form-control ${errors.email ? "is-invalid" : ""}`}
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                  </div>

                  <div className="col-12">
                    <label htmlFor="password" className="form-label" style={labelStyles}>
                      Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${errors.password ? "is-invalid" : ""}`}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>

                  <div className="col-12 form-check">
                    <input
                      type="checkbox"
                      className="ml-5 form-check-input"
                      id="showPassword"
                      checked={showPassword}
                      onChange={togglePasswordVisibility}
                    />
                    <label htmlFor="showPassword" className="form-check-label" style={labelStyles}>
                      Show Password
                    </label>
                  </div>

                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn w-100"
                      style={buttonStyles}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Login"}
                    </button>
                  </div>

                  <div className="col-12 text-center mb-2">
                    <p className="small mb-0">Ashley Hotel Group</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

const cardStyles = {
  borderBottom: "3px solid #575755",
  borderRight: "4px solid #575755",
  borderRadius: "12px",
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
  transition: "transform 0.3s ease-in-out",
};

const labelStyles = { fontSize: "12px" };

const buttonStyles = {
  backgroundColor: "#575755",
  color: "#fff",
  border: "none",
};

export default Login;
