import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import Swal from "sweetalert2";
import Moment from "moment";
import { setUserSession } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import _ from "lodash";

function ItemRejectProposal() {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const FileDownload = require("js-file-download");
  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;
  const [loading, setLoading] = useState(true);

  const downloadPurchaseProposalItemReject = () => {
    axios
      .get(
        `/api/generate/excel/report/item/reject/proposal/${hcode}/${udeptID}`,
        { responseType: "blob" },
        { headers: headers }
      )
      .then(function (response) {
        // console.log(response.data);
        FileDownload(response.data, "item_reject_purchase_proposal.xlsx");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // Var
  const [purchaseProposal, setPurchaseProposal] = useState([]);
  const [hotelList, setHotelList] = useState([]);

  const getListHotelbyUser = useCallback(() => {
    axios
      .post(`/api/list/hotel/by-user`, { hcode }, { headers: headers })
      .then(function (response) {
        setHotelList(response.data);
      })
      .catch(function (error) {
        console.log("Error API Hotel: ", error);
      });
  }, [hcode, headers]);
  //const ExpandedComponent = ({ data }) => <pre>{data.title}</pre>;

  const columns = [
    {
      name: "PP No.",
      width: "200px",
      wrap: true,
      selector: (row) => row.nomer,
    },
    {
      name: "Origin",
      wrap: true,
      cell: (row) => (
        <div>
          <b>For Hotel</b> : {row.nm_hotel}
          <br />
          <b>From Department: </b> {row.nm_department}
        </div>
      ),
    },
    {
      name: "Remark",
      wrap: true,
      cell: (row) => (
        <div
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            color: "#580C30",
          }}
        >
          {row.remark}
        </div>
      ),
    },
    {
      name: "Created",
      cell: (row) => Moment(row.created_at).format("LLL"),
    },
    {
      name: " Need For",
      cell: (row) => Moment(row.date_need).format("LL"),
    },
    {
      name: "Reject By",
      wrap: true,
      cell: (row) => (
        <>
          <i>{row.name}</i>
        </>
      ),
    },
    {
      name: "",
      width: "50px",
      cell: (row) => (
        <div onClick={() => history.push(`/detail/proposal/${row.id_pp}`)}>
          <i className="bi bi-search"></i>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "13px",
      },
    },
    cells: {
      style: {
        fontSize: "11px",
      },
    },
  };

  // Search
  const [filtertext, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = purchaseProposal.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext.toLowerCase())
  );

  const handleClear = () => {
    if (filtertext) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const getPurchaseProposalItemReject = async (hotel) => {
    setLoading(true);
    try {
      const postData = { hcode };
      if (hotel) {
        postData.hcode = hotel;
      }

      const response = await axios.post(`/api/item/reject/proposal`, postData, {
        headers,
      });

      // console.log(response.data);
      setPurchaseProposal(Object.values(response.data).flatMap((arr) => arr));
    } catch (error) {
      console.log("Error getAPIListPPA: ", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedGetPurchaseProposalItemReject = _.debounce(
    getPurchaseProposalItemReject,
    200
  );

  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 200);

  useEffect(() => {
    debouncedGetPurchaseProposalItemReject();
    debouncedGetListHotelbyUser();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h6
            className="mb-0"
            style={{ fontWeight: "bold", marginLeft: "7px" }}
          >
            Purchase Proposal
          </h6>
          <nav>
            <ol className="breadcrumb mt-1 mb-2 ms-2">
              <li
                className="breadcrumb-item "
                style={{ cursor: "pointer", fontSize: "13px" }}
                onClick={() => history.push("/dashboard")}
              >
                Home
              </li>
              <li style={{ fontSize: "13px" }} className="breadcrumb-item ">
                Purchase Proposal
              </li>
              <li
                style={{ fontSize: "13px" }}
                className="breadcrumb-item active"
              >
                Purchase Rejected
              </li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row p-0 pb-2 mb-2 mt-3">
                    <div className="col-1 pe-0">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "12px" }}
                        onClick={() => debouncedGetPurchaseProposalItemReject()}
                      >
                        <i className="bi bi-arrow-clockwise"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "1%" }}
                        onClick={() => downloadPurchaseProposalItemReject()}
                      >
                        <i className="bi bi-printer"></i>
                      </button>
                    </div>

                    <div className="col-7 ps-1">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setFilterText(e.target.value)}
                        onClear={() => handleClear}
                        filtertext={filtertext}
                      />
                    </div>

                    <div className="col-4">
                      <select
                        id="hotelSelect"
                        class="form-select form-select-sm"
                        onChange={(e) =>
                          debouncedGetPurchaseProposalItemReject(e.target.value)
                        }
                      >
                        <option>Group By Hotel</option>
                        {hotelList.map((x, t) =>
                          x.map((v, k) => (
                            <option key={k} value={v.id_hotel}>
                              {v.code_hotel} - {v.nm_hotel}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  {loading ? (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                      <div style={{ marginTop: "10px" }}>Loading...</div>
                    </div>
                  ) : (
                    <div className="row">
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        customStyles={customStyles}
                        fixedHeader
                        fixedHeaderScrollHeight="440px"
                        pagination
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default ItemRejectProposal;
