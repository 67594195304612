import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import axios from "../../utils/axios";
import { getToken } from "../../utils/Common";
import { Image, Transformation } from "cloudinary-react";
import Moment from "moment";
import Swal from "sweetalert2";
import Select from "react-select";
//import { setUserSession } from '../../utils/Common';
import { Modal, ModalFooter, OverlayTrigger, Tooltip } from "react-bootstrap";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import _ from "lodash";

function Detail() {

  const FileDownload = require("js-file-download");
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const session = localStorage.getItem("user");
  const params = useParams();
  const uid = JSON.parse(session).id;
  const PPID = params.IDPP;

  const numberFormat = (value) =>
    new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumSignificantDigits: 7,
  }).format(value);

  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [modalShowImage, setModalShowImage] = useState(false);
  const [showModalEditSupplier, setShowModalEditSupplier] = useState(false);
  const [showModalEditPP, setShowModalEditPP] = useState(false);
  const [detailItemPP, setDetailItemPP] = useState([]);
  const [detailItem, setDetailItem] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [showModalAddSupplier, setModalShowAddSupplier] = useState(false);
  const [IDPPtoSupplier, setIDPPtoSupplier] = useState();
  const [IDDETPPtoSupplier, setIDDETPPtoSupplier] = useState();
  const [itemName, setItemName] = useState();
  const [supplierSelect, setSupplierSelect] = useState();
  const [PPtoPR, setPPtoPR] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const getDetailItem = (ITEMID) => {
    axios
      .get(`/api/detail/item/${ITEMID}`, { headers: headers })
      .then(function (response) {
        // console.log(response.data)
        setDetailItem(response.data);
        setModalShowImage(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getDetailItemPP = (IDDETPP) => {
    axios
      .get(`/api/detail/pp/item/${IDDETPP}`, { headers: headers })
      .then(function (response) {
        console.log("detailITEMPP", response.data);
        setDetailItemPP(response.data);

        var forDept = response.data.department_id;
        var forHot = response.data.hotel_id;

        axios
          .post(`/api/list/items/pp`, { forDept, forHot }, { headers: headers })
          .then(function (response) {
            // console.log(response.data)
            setItemList(Object.values(response.data).flatMap((arr) => arr));
            setModalShowEdit(true);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const printPR = () => {
    axios.get(`/api/print/pr/${PPID}`,{ responseType: "blob" },{ headers: headers })
    .then(function (response) {
      // console.log(response.data)
      FileDownload(response.data, PPID + "_PR.pdf");
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  // Edit Item
  const onSelectItemChange = (e) => {
    setDetailItemPP({ ...detailItemPP, ["item_id"]: e.value });
  };

  const [headerPP, setHeaderPP] = useState([]);
  const [flowPP, setFlowPP] = useState([]);
  const [detailPP, setDetailPP] = useState([]);
  const [listSuppPP, setListSuppPP] = useState([]);

  const [modalShowPrintPR, setModalShowPrintPR] = useState(false);
  const [ModalShowUploadPO, setModalShowUploadPO] = useState(false);

  const [DETPPID, setDETPPID] = useState();
  const [SUPPID, setSUPPID] = useState();
  const [template, setTemplate] = useState();
  const [POValid, setPOValid] = useState();
  const [ExReceived, setExReceived] = useState();
  const [commentPO, setCommentPO] = useState();

  const [emailSupplier, setEmailSupplier] = useState();
  const [phoneSupplier, setPhoneSupplier] = useState();
  const [filePO, setFilePO] = useState();

  const getDetailToPrintPR = (x, y) => {
    setDETPPID(x);
    setSUPPID(y);
    setModalShowPrintPR(true);
  };

  const doPrint = () => {
    if (!template || !POValid || !ExReceived || !DETPPID || !SUPPID) {
      Swal.fire({
        icon: "info",
        text: "Please fill the form  PO correctly",
      });
    } else {
      Swal.fire({
        text: "Do you want to Print this Purchase Request?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //Process PRINT PR
          axios
            .get(
              `/api/print/po/${PPID}/${DETPPID}/${SUPPID}/${template}/${POValid}/${ExReceived}/${commentPO}`,
              { responseType: "blob" },
              { headers: headers }
            )
            .then(function (response) {
              // console.log(response.data)
              setModalShowPrintPR(false);
              getSupplierPP();
              FileDownload(response.data, PPID + ".pdf");
            })
            .catch(function (error) {
              console.log(error);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const doPrintPR = () => {
    //Process PRINT PR
    axios
      .get(
        `/api/print/pr/${PPID}`,
        { responseType: "blob" },
        { headers: headers }
      )
      .then(function (response) {
        // console.log(response.data)
        FileDownload(response.data, PPID + ".pdf");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // VAR SUPPLIER CHOOSE
  const [supplierComparation, setSupplierComparation] = useState();
  const [priceSupplier, setPriceSupplier] = useState();
  const [taxSupplier, setTaxSupplier] = useState();
  const [remarkSupplier, setRemarkSupplier] = useState();
  const [QtyItem, setQtyItem] = useState();

  //VAR edit and select supplier
  const [selectedSupplier, setSelectedSupplier] = useState({
    ppid: 0,
    stts_pp: 0,
    detPPid: 0,
    reqPPid: 0,
    supplier: "",
    supplierID: 0,
    item: "",
    quantity: 0,
    price: 0,
    tax: 0,
    remark: "",
  });

  //VAR edit and sendback PP
  const [editedPP, setEditedPP] = useState({
    ppid: 0,
    stts_pp: 0,
    remark: "",
    purchasing_remark: "",
  });

  // for log flow
  // Calculation Time
  const [hmDay, setHMDay] = useState();
  const [dooDay, setDOODay] = useState();
  const [prcDay, setPRCDay] = useState();
  const [pmDay, setPMDay] = useState();
  const [caDay, setCADay] = useState();
  const [dooPRDay, setDOOPRDay] = useState();
  const [mdPRDay, setMDPRDay] = useState();

  const getHeaderPP = async () => {
    try {
      const response = await axios.get(`/api/header/proposal/${PPID}`, {
        headers: headers,
      });
      console.log("headerPP:", response.data);
      setHeaderPP(response.data);
    } catch (error) {
      console.log("Error getAPIListPP: ", error);
    }
  };

  const getFlowPP = () => {
    axios
      .get(`/api/flow/${PPID}`, { headers: headers })
      .then(function (response) {
        console.log("flowPP:", response.data);
        setFlowPP(response.data[0]);
        // console.log(response.data)

        //////////////////////////////////////// LOG TIME /////////////////////////////////////////////////
        if (response.data[0].hm_action) {
          setHMDay(
            response.data[1].d +
              " day " +
              response.data[1].h +
              " hours " +
              response.data[1].i +
              " minutes"
          );
        } else {
          setHMDay("Waiting for Action");
        }

        if (response.data[0].doo_action) {
          setDOODay(
            response.data[2].d +
              " day " +
              response.data[2].h +
              " hours " +
              response.data[2].i +
              " minutes"
          );
        } else {
          setDOODay("Waiting for Action");
        }

        if (response.data[0].prcaction) {
          setPRCDay(
            response.data[3].d +
              " day " +
              response.data[3].h +
              " hours " +
              response.data[3].i +
              " minutes"
          );
        } else {
          setPRCDay("Waiting for Action");
        }

        if (response.data[0].pm_action) {
          setPMDay(
            response.data[4].d +
              " day " +
              response.data[4].h +
              " hours " +
              response.data[4].i +
              " minutes"
          );
        } else {
          setPMDay("Waiting for Action");
        }

        if (response.data[0].ca_action) {
          setCADay(
            response.data[5].d +
              " day " +
              response.data[5].h +
              " hours " +
              response.data[5].i +
              " minutes"
          );
        } else {
          setCADay("Waiting for Action");
        }

        if (response.data[0].prdoo) {
          setDOOPRDay(
            response.data[6].d +
              " day " +
              response.data[6].h +
              " hours " +
              response.data[6].i +
              " minutes"
          );
        } else {
          setDOOPRDay("Waiting for Action");
        }

        if (response.data[0].ceo_action) {
          setMDPRDay(
            response.data[7].d +
              " day " +
              response.data[7].h +
              " hours " +
              response.data[7].i +
              " minutes"
          );
        } else {
          setMDPRDay("Waiting for Action");
        }
      })
      .catch(function (error) {
        console.log("Error getAPIFlowPP: ", error);
      });
  };

  const getDetailPP = () => {
    axios
      .get(`/api/detail/proposal/${PPID}`, { headers: headers })
      .then(function (response) {
        // console.log("getDetailPP:", response.data);
        setDetailPP(response.data);
      })
      .catch(function (error) {
        console.log("Error getAPIDetailPP: ", error);
      });
  };

  const checkPPtoPR = () => {
    //Process Check PP to PR
    axios
      .get(`/api/pp/checking/${PPID}`, { headers: headers })
      .then(function (response) {
        // console.log("PPtoPR = ", response.data);
        // console.log("ppid = ", PPID);
        if (response.data === 2) {
          setPPtoPR(false);
        }
      })
      .catch(function (error) {
        setPPtoPR(true);
      });
  };

  const getSupplierPP = async () => {
    try {
      const response = await axios.get(`/api/supplier/list/purchase/proposal/${PPID}`,{ headers: headers });
      // console.log("list supp PP:", response.data);
      setListSuppPP(response.data);
    } catch (error) {
      console.log("Error getAPIListSupplier: ", error);
    }
  };

  const returnPPUser = async () => {
    if (!editedPP.purchasing_remark) {
      Swal.fire({
        icon: "info",
        text: "Please fill the purchasing remark",
      });
    } else {
      Swal.fire({
        text: "Are you sure?",
        text: "Caution! Purchase Proposal will returned back to User and all the PR / PO records will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //Process Submit
          axios
            .post(`/api/pp/return/user`, { PPID, uid }, { headers: headers })
            .then(function (response) {
              // console.log(response.data)
              Swal.fire({
                icon: "success",
                text: "Purchase Proposal has been returned to User",
              });
            })
            .catch(function (error) {
              console.log(error);
              Swal.fire("Option not available", "", "info");
            });
        } else if (result.isDenied) {
          Swal.fire("Action cancelled", "", "info");
        }
      });
    }
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const deletePP = async () => {
    Swal.fire({
      text:
        "Are you sure you want to cancel and delete the entire Purchase Proposal?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //Process Submit
        console.log("result is confirmed");
        axios
          .post(`/api/pp/delete`, { PPID }, { headers: headers })
          .then(function (response) {
            // console.log(response.data)
            Swal.fire({
              icon: "success",
              text: "Purchase Proposal has been canceled and deleted",
            });
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire("Option not available", "", "info");
          });
      } else {
        Swal.fire("Action cancelled", "", "info");
      }
    });
  };

  const updateItemPP = () => {
    if (!detailItemPP.item_id || !detailItemPP.quantity) {
      Swal.fire({
        icon: "info",
        text: "Please fill the form correctly",
      });
    } else {
      Swal.fire({
        text: "Do you want to update item proposal?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //Process Submit
          axios
            .post(`/api/update/item/pp`, { detailItemPP }, { headers: headers })
            .then(function (response) {
              // console.log(response.data)
              Swal.fire({
                icon: "success",
                text: "Item has been update",
              });
              setModalShowEdit(false);
              getDetailPP();
            })
            .catch(function (error) {
              console.log(error);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const deleteItemPP = () => {
    if (!detailItemPP.item_id || !detailItemPP.quantity) {
      Swal.fire({
        icon: "info",
        text: "Please fill the form correctly",
      });
    } else {
      Swal.fire({
        title: "Do you want to delete this item proposal?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //Process Submit
          axios
            .post(
              `/api/delete/item/pp`,
              { detailItemPP, uid },
              { headers: headers }
            )
            .then(function (response) {
              // console.log(response.data)
              Swal.fire({
                icon: "success",
                text: "Item has been update",
              });
              setModalShowEdit(false);
              getDetailPP();
            })
            .catch(function (error) {
              console.log(error);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const getAllSupplierList = () => {
    axios
      .get(`/api/select/list/supplier`, { headers: headers })
      .then(function (response) {
        setSupplierSelect(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const openModalAddSupplier = (IDPP, detPPID, item, qty) => {
    // alert(detPPID)
    getAllSupplierList();
    setItemName(item);
    setQtyItem(qty);
    setIDPPtoSupplier(IDPP);
    setIDDETPPtoSupplier(detPPID);
    setModalShowAddSupplier(true);
  };

  const openModalEditSupplier = (supplier) => {
    getAllSupplierList();
    setSelectedSupplier({
      ppid: supplier.pp_id,
      stts_pp: supplier.stts_pp,
      detPPid: supplier.id_det_purchase_proposal,
      reqPPid: supplier.id_request_proposal,
      supplier: supplier.companyname,
      supplierID: supplier.supplier_id,
      item: supplier.nama_item,
      quantity: supplier.quantity,
      price: supplier.quotation_price,
      tax: supplier.tax || 0, // Assuming tax might not always be present
      remark: supplier.remark || "",
    });
    setShowModalEditSupplier(true);
  };

  const openModalEditPP = (pp) => {
    setEditedPP({
      ppid: pp.pp_id,
      stts_pp: pp.stts_pp,
      nomer: pp.nomer,
      remark: pp.remark,
      purchasing_remark: pp.purchasing_remark,
    });
    setShowModalEditPP(true);
  };

  const onSelectSelectedSupplier = (e) => {
    const selected = supplierSelect.find((supp) => supp.value === e.value);
    setSelectedSupplier({
      ...selectedSupplier,
      supplier: selected.label,
      supplierID: selected.value, // Update supplierID here
    });
  };

  const onSelectSupplier = (e) => {
    setSupplierComparation(e.value);
  };

  const cekPRBalancewithPO = () => {
    axios
      .get(`/api/checking/pr/balance/po/${PPID}`, { headers: headers })
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const submitSupplier = () => {
    if (
      !supplierComparation ||
      !priceSupplier ||
      !taxSupplier ||
      !remarkSupplier
    ) {
      Swal.fire({
        icon: "info",
        text: "Please fill  supplier data correctly",
      });
    } else {
      Swal.fire({
        icon:"question",
        text: "Do you want to submit supplier into this proposal?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setSubmitting(true);
          // Process Submit Supplier PP
          axios
            .post(
              `/api/supplier/add/purchase/proposal`,
              {
                supplierComparation,
                priceSupplier,
                taxSupplier,
                remarkSupplier,
                QtyItem,
                IDPPtoSupplier,
                IDDETPPtoSupplier,
              },
              { headers: headers }
            )
            .then(function (response) {
              // console.log(response.data)
              setSubmitting(false);
              setModalShowAddSupplier(false);

              if (response.data !== "true") {
                Swal.fire({
                  icon: "info",
                  text: "Submit Successful",
                });
              }

              getSupplierPP();
              getFlowPP();
              checkPPtoPR();
              setSubmitting(false);
            })
            .catch(function (error) {
              setSubmitting(false);
              console.log(error);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const editSupplier = () => {
    if (
      !selectedSupplier.supplier ||
      !selectedSupplier.price ||
      !selectedSupplier.remark
    ) {
      Swal.fire({
        icon: "info",
        text: "Please fill supplier data correctly",
      });
    } else {
      Swal.fire({
        title: "Do you want to save changes into this Proposal Request?",
        text: "This action will return it's PP Status to 'PM Approved - Waiting CA Approve'",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // Process Submit Supplier PP
          setSubmitting(true);
          axios
            .post(
              `/api/pp/goback`,
              {
                ppid: selectedSupplier.ppid,
                stts_pp: selectedSupplier.stts_pp,
                detPPid: selectedSupplier.detPPid,
                reqPPid: selectedSupplier.reqPPid,
                supplierName: selectedSupplier.supplier,
                supplierID: selectedSupplier.supplierID,
                priceSupplier: selectedSupplier.price,
                quantity: selectedSupplier.quantity,
                taxSupplier: selectedSupplier.tax,
                remarkSupplier: selectedSupplier.remark,
              },
              { headers: headers }
            )
            .then(function (response) {
              // console.log(response.data)
              setSubmitting(false);
              setShowModalEditSupplier(false);

              if (response.data !== "true") {
                Swal.fire({
                  icon: "success",
                  text: "Purchase Request has been revised and sent back to CAA",
                });
              }

              getSupplierPP();
              getFlowPP();
              checkPPtoPR();
              setSubmitting(false);
            })
            .catch(function (error) {
              if (error.response && error.response.status === 400) {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: error.response.data.error,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "An unexpected error occurred.",
                });
              }
              setSubmitting(false);
            });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
    }
  };

  const SupplierChoose = (IDPR, DETPPID, SUPID) => {
    // Process Remove Supplier PP
    axios
      .get(
        `/api/supplier/choose/purchase/proposal/${IDPR}/${DETPPID}/${SUPID}`,
        { headers: headers }
      )
      .then(function (response) {
        // console.log(response.data)
        getSupplierPP();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const RemoveSupplier = (IDPR) => {
    Swal.fire({
      icon:"question",
      text: "Do you want to remove supplier from this proposal?",
      showCancelButton: true,
      confirmButtonText: "Yes, Sure",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Process Remove Supplier PP
        axios
          .get(`/api/supplier/remove/purchase/proposal/${IDPR}`, {
            headers: headers,
          })
          .then(function (response) {
            // console.log(response.data)
            getSupplierPP();
            checkPPtoPR();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const submitToPR = () => {
    Swal.fire({
      title: "Do you want to submit this Proposal to Purchase Request?",
      showCancelButton: true,
      confirmButtonText: "Yes, Sure",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Process Submit Supplier PP
        axios
          .get(`/api/submit/proposal/to/request/${PPID}`, { headers: headers })
          .then(function (response) {
            window.location.reload();
            // console.log(response.data)
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const splitItemPP = (DETPPID) => {
    Swal.fire({
      icon:"question",
      text: "Do you want to split this item to another proposal?",
      showCancelButton: true,
      confirmButtonText: "Yes, Sure",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Process Split Item PP
        axios
          .get(`/api/split/item/pp/${DETPPID}`, { headers: headers })
          .then(function (response) {
            window.location.reload();
          })
          .catch(function (error) {
            console.log(error);
          });
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  const getDetailToUpload = (x, y) => {
    setDETPPID(x);
    setSUPPID(y);
    setModalShowUploadPO(true);
  };

  const uploadPOFileNew = (e) => {
    setFilePO(e.target.files[0]);
  };

  const doUploadPO = async () => {
    if (!template) {
      Swal.fire({
        icon: "info",
        text: "Please select the template",
      });
      return; // Stop further execution if template is not provided
    }

    if (
      !PPID ||
      !DETPPID ||
      !SUPPID ||
      !phoneSupplier ||
      !filePO ||
      !template
    ) {
      Swal.fire({
        icon: "info",
        text: "Please fill PO data correctly",
      });
    } else {
      const result = await Swal.fire({
        title: "Do you want to submit supplier into this proposal?",
        showCancelButton: true,
        confirmButtonText: "Yes, Sure",
      });

      if (result.isConfirmed) {
        try {
          // Process Submit Supplier PP
          setUploading(true);
          // Append all fields to the FormData object to send image and other data
          const formData = new FormData();
          formData.append("PPID", PPID);
          formData.append("DETPPID", DETPPID);
          formData.append("SUPPID", SUPPID);
          formData.append("emailSupplier", emailSupplier);
          formData.append("phoneSupplier", phoneSupplier);
          formData.append("filePO", filePO);
          formData.append("template", template);

          const response = await axios.post(`/api/upload/send/po`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          console.log(response.data);
          setModalShowUploadPO(false);
          setUploading(false);
          await Swal.fire({
            icon: "success",
            text: "PO Updated & Sent",
          });
          // Reload the page after success
          //window.location.reload();
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: "error",
            text: "An error occurred while uploading the PO",
          });
        }
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    }
  };

  
  const debouncedCekPRBalancewithPO = _.debounce(cekPRBalancewithPO, 500);
  const debouncedCheckPPtoPR = _.debounce(checkPPtoPR, 500);
  const debouncedGetHeaderPP = _.debounce(getHeaderPP, 500);
  const debouncedGetFlowPP = _.debounce(getFlowPP, 500);
  const debouncedGetDetailPP = _.debounce(getDetailPP, 500);
  const debouncedGetSupplierPP = _.debounce(getSupplierPP, 500);

  useEffect(() => {
    debouncedCekPRBalancewithPO();
    debouncedCheckPPtoPR();
    debouncedGetHeaderPP();
    debouncedGetFlowPP();
    debouncedGetDetailPP();
    debouncedGetSupplierPP();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
         <nav>
           <ol className="breadcrumb">
             <li className="breadcrumb-item">Detail</li>
             <li className="breadcrumb-item active">{headerPP.nomer}</li>
             <li className="breadcrumb-item">{headerPP.need} </li>
           </ol>
         </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body pb-0">

                  <div className="card-title">
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px"}}>

                    <div style={{ display: "flex", gap: "20px",alignItems: "center" }}>

                      <span style={{ display: "flex", alignItems: "left", gap: "5px" }}>
                        <i className="bi bi-bag-plus"></i>
                        {Moment(headerPP.date_need).format("LL")}
                      </span>

                      <span style={{ display: "flex", alignItems: "left", gap: "5px" }}>
                        <i className="bi bi-pencil"></i>
                        {Moment(headerPP.created_at).format("LLL")}
                      </span>
                    </div>  

                      <span style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer"}} onClick={() => printPR()}>
                        <i className="bi bi-printer" style={{ fontSize: "18px" }}></i>
                      </span>
                    </div>
                  </div>

                  <p className="mt-2 mb-2" style={{fontSize: "16px",fontWeight:"bold",color:"GrayText" }}>
                    *{headerPP.remark}
                  </p>

                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" style={{textAlign:"center"}}>Thumbnail</th>
                          <th scope="col" style={{textAlign:"center"}}>Item</th>
                          <th scope="col" style={{textAlign:"center"}}>Status</th>
                          <th scope="col" style={{textAlign:"center"}}>Qty</th>
                          <th scope="col" style={{textAlign:"center"}}>Group</th>
                          <th scope="col" style={{ maxWidth: "80px" }}>URL</th>
                          <th scope="col"> Remark</th>
                          {headerPP.stts_pp === 3 && <th scope="col"  style={{ width: "50px" }}>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {detailPP?.map((v, k) => (
                          <tr key={k}>
                            <th scope="row" className="text-center pt-1" style={{ width: "80px", cursor: "pointer" }} onClick={() => getDetailItem(v.item_id)}>
                              <img src={v.img_item} alt="Item ashley hotel group" style={{ maxWidth: "120px", maxHeight:"120px" }} />
                            </th>
                            <td style={{ width: "150px", cursor: "pointer",textAlign:"center",verticalAlign: "middle" }} onClick={() => getDetailItemPP(v.id_det_purchase_proposal)}>
                              <span className="text-warning fw-bold">
                                {v.nama_item}
                              </span>
                            </td>
                            <td style={{ width: "55px",textAlign:"center",verticalAlign: "middle" }}>
                              {v.delete_stts === 0 && (
                                <span className="text-success fw-bold">
                                  Active
                                </span>
                              )}
                              {v.delete_stts > 0 && (
                                <span className="text-danger fw-bold">
                                  Rejected by {v.deleteby}
                                </span>
                              )}
                            </td>
                            <td style={{ width: "55px",textAlign:"center",verticalAlign: "middle" }}>{v.quantity} {v.unit}</td>
                            <td className="fw-bold" style={{ width: "30px" ,textAlign:"justify",verticalAlign: "middle"}}>{v.group_item}</td>
                            <td style={{ width: "30px",textAlign:"center",verticalAlign: "middle" }}>{v.url_item}</td>
                            <td style={{ width: "200px",textAlign:"center",verticalAlign: "middle" }}>{v.comment_item}</td>
                            
                            {headerPP.stts_pp === 3 && (
                              <td style={{textAlign:"center",verticalAlign: "middle"}}>
                                <figure
                                  className="text-center mt-2"
                                  onClick={() =>
                                    openModalAddSupplier(
                                      v.pp_id,
                                      v.id_det_purchase_proposal,
                                      v.nama_item,
                                      v.quantity
                                    )
                                  }
                                >
                                  <span className="badge bg-info text-white">
                                    + Supplier
                                  </span>
                                </figure>
                                <figure
                                  className="text-center mt-2"
                                  onClick={() =>
                                    splitItemPP(v.id_det_purchase_proposal)
                                  }
                                >
                                  <span className="badge bg-warning text-white">
                                    Split Item
                                  </span>
                                </figure>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {/* Flow */}
            <div className="col-5">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Activity <span>| Flow</span>
                  </h5>

                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Date/Time</th>
                        <th scope="col">Action</th>
                        <th scope="col">Role</th>
                        <th scope="col">Comment</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-info">
                        <th scope="row">
                          {Moment(headerPP.created_at).format("LLL")}
                        </th>
                        <td></td>
                        <td>Users</td>
                        <td></td>
                        <td>
                          <span className="badge bg-info">Requested</span>
                        </td>
                      </tr>

                      <tr className="table-info">
                        <th scope="row">
                          {Moment(flowPP.hm_action).format("LLL")}
                        </th>
                        <td>{hmDay}</td>
                        <td>Hotel Manager</td>
                        <td>{flowPP.hm_comment}</td>
                        <td>
                          {flowPP.stts_pp === 22 && (
                            <span className="badge bg-danger">Rejected</span>
                          )}

                          {flowPP.stts_pp >= 2 && flowPP.stts_pp <= 11 && (
                            <span className="badge bg-success">Approved</span>
                          )}
                        </td>
                      </tr>

                      <tr className="table-info">
                        <th scope="row">
                          {Moment(flowPP.doo_action).format("LLL")}
                        </th>
                        <td>{dooDay}</td>
                        <td>Director of Operation</td>
                        <td>{flowPP.doo_comment}</td>
                        <td>
                          {flowPP.stts_pp === 33 && (
                            <span className="badge bg-danger">Rejected</span>
                          )}

                          {flowPP.stts_pp >= 3 && flowPP.stts_pp <= 11 && (
                            <span className="badge bg-success">Approved</span>
                          )}
                        </td>
                      </tr>

                      <tr className="table-warning">
                        <th scope="row">
                          {Moment(flowPP.prcaction).format("LLL")}
                        </th>
                        <td>{prcDay}</td>
                        <td>Purchasing Team</td>
                        <td>{flowPP.prcremark}</td>
                        <td>
                          {flowPP.stts_pp >= 4 && flowPP.stts_pp <= 11 && (
                            <span className="badge bg-warning">
                              Supplier Found
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr className="table-warning">
                        <th scope="row">
                          {Moment(flowPP.pm_action).format("LLL")}
                        </th>
                        <td>{pmDay}</td>
                        <td>Purchasing Manager</td>
                        <td></td>
                        <td>
                          {flowPP.stts_pp === 55 && (
                            <span className="badge bg-danger">Rejected</span>
                          )}

                          {flowPP.stts_pp >= 5 && flowPP.stts_pp <= 11 && (
                            <span className="badge bg-success">Approved</span>
                          )}
                        </td>
                      </tr>

                      <tr className="table-warning">
                        <th scope="row">
                          {Moment(flowPP.ca_action).format("LLL")}
                        </th>
                        <td>{caDay}</td>
                        <td>Chief Accounting</td>
                        <td></td>
                        <td>
                          {flowPP.stts_pp === 66 && (
                            <span className="badge bg-danger">Rejected</span>
                          )}

                          {flowPP.stts_pp >= 6 && flowPP.stts_pp <= 11 && (
                            <span className="badge bg-success">Approved</span>
                          )}
                        </td>
                      </tr>

                      <tr className="table-warning">
                        <th scope="row">
                          {Moment(flowPP.prdoo).format("LLL")}
                        </th>
                        <td>{dooPRDay}</td>
                        <td>Director of Operation</td>
                        <td></td>
                        <td>
                          {flowPP.stts_pp === 77 && (
                            <span className="badge bg-danger">Rejected</span>
                          )}

                          {flowPP.stts_pp >= 7 && flowPP.stts_pp <= 11 && (
                            <span className="badge bg-success">Approved</span>
                          )}
                        </td>
                      </tr>

                      <tr className="table-warning">
                        <th scope="row">
                          {Moment(flowPP.ceo_action).format("LLL")}
                        </th>
                        <td>{mdPRDay}</td>
                        <td>Managing Director</td>
                        <td></td>
                        <td>
                          {flowPP.stts_pp === 88 && (
                            <span className="badge bg-danger">Rejected</span>
                          )}

                          {flowPP.stts_pp >= 8 && flowPP.stts_pp <= 11 && (
                            <span className="badge bg-success">Approved</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Supplier */}
            <div className="col-7">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Supplier <span>| Comparation</span>{" "}
                  </h5>
                  {listSuppPP.length > 0 ? (
                    <table className="table table-hover table-striped table-bordered">
                      <thead>
                        <tr>
                          {headerPP.stts_pp === 3 && <th scope="col"></th>}
                          <th style={{ width: "110px" }} scope="col">Supplier</th>
                          <th style={{ width: "140px" }} scope="col">Item</th>
                          <th scope="col">Price</th>
                          <th style={{ width: "140px" }}>Status</th>
                          <th scope="col">Action</th>
                          {headerPP.stts_pp === 3 && <th scope="col"></th>}
                        </tr>
                      </thead>
                      <tbody>
                        {listSuppPP?.map((v, k) => (
                          <tr className="table-default" key={k}>
                            {headerPP.stts_pp === 3 && (
                              <th>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={v.nama_item}
                                  id={v.nama_item}
                                  onClick={() =>
                                    SupplierChoose(
                                      v.id_request_proposal,
                                      v.id_det_purchase_proposal,
                                      v.idSupplier
                                    )
                                  }
                                />
                              </th>
                            )}

                            <th scope="row">{v.companyname}</th>
                            <td>{v.nama_item}</td>
                            <td>{numberFormat(v.quotation_price)}</td>
                            <td>
                              {v.file_po && v.date_po_send && v.supplier_choose == v.supplier_id && (<div>PO Sent</div>)}
                              {!v.valid_po && <div>Not Printed</div>}
                              {!v.date_po_send && <div>Not Sent</div>}
                              {v.receive_status === 1 && v.supplier_choose == v.supplier_id && (<div>Item Received</div>)}
                            </td>

                            {/* Function with condition */}
                            <td className="text-center">
                              {v.supplier_id === v.supplier_choose && (
                                <div className="btn-group " role="group">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-info dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    style={{fontSize:"12px",textAlign:"center",verticalAlign:"center"}}
                                  >
                                    Actions
                                  </button>
                                  <ul className="dropdown-menu">
                                    {/* Print & Generate PO */}
                                    {v.supplier_id === v.supplier_choose &&
                                      headerPP.stts_pp >= 8 &&
                                      headerPP.stts_pp < 11 && (
                                        <li>
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip-print">Print & Generate PO</Tooltip>}
                                          >
                                            <button
                                              className="dropdown-item"
                                              onClick={() =>
                                                getDetailToPrintPR(v.id_det_purchase_proposal, v.idSupplier)
                                              }
                                            >
                                              <i className="bi bi-printer me-2"></i>Print & Generate 
                                            </button>
                                          </OverlayTrigger>
                                        </li>
                                      )}

                                    {/* Upload PO and Send */}
                                    {v.supplier_id === v.supplier_choose &&
                                      headerPP.stts_pp >= 8 &&
                                      headerPP.stts_pp < 11 && (
                                        <li>
                                          <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="tooltip-upload">Upload PO and Send</Tooltip>}
                                          >
                                            <button
                                              className="dropdown-item"
                                              onClick={() =>
                                                getDetailToUpload(v.id_det_purchase_proposal, v.idSupplier)
                                              }
                                            >
                                              <i className="bi bi-upload me-2"></i>Upload and Send
                                            </button>
                                          </OverlayTrigger>
                                        </li>
                                      )}

                                    {/* View PO */}
                                    {v.supplier_id === v.supplier_choose && v.file_po && (
                                      <li>
                                        <OverlayTrigger
                                          placement="right"
                                          overlay={<Tooltip id="tooltip-view">View PO</Tooltip>}
                                        >
                                          <button
                                            className="dropdown-item"
                                            onClick={() => window.open(v.file_po, "_blank")}
                                          >
                                            <i className="bi bi-file-earmark-pdf-fill me-2"></i>View 
                                          </button>
                                        </OverlayTrigger>
                                      </li>
                                    )}

                                    {/* Revise Supplier / Price */}
                                    {headerPP.stts_pp >= 5 &&
                                      headerPP.stts_pp < 11 &&
                                      v.receive_status !== 1 && (
                                        <li>
                                          <OverlayTrigger
                                            placement="right"
                                            overlay={
                                              <Tooltip id="tooltip-revise">Revise Supplier / Price</Tooltip>
                                            }
                                          >
                                            <button
                                              className="dropdown-item"
                                              onClick={() => openModalEditSupplier(v)}
                                            >
                                              <i className="bi bi-pen-fill me-2"></i>Revise Supplier / Price
                                            </button>
                                          </OverlayTrigger>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              )}
                            </td>


                            {headerPP.stts_pp === 3 && (
                              <td>
                                <span className="badge bg-danger" style={{ fontSize: "16px" }} onClick={() => RemoveSupplier(v.id_request_proposal)}>
                                  <i className="bi bi-eraser-fill"></i>
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <>No Record</>
                  )}
                </div>
               
              </div>
            </div>
          </div>
        </section>


      {/* Floating Button */}
      {PPtoPR && headerPP.stts_pp === 3 && (
        <div className="position-fixed" style={{ bottom: '20px', right: '20px' }}>
          <div className="rounded-circle border border-5 border-primary outer-ring" 
            style={{ 
              width: '70px', 
              height: '70px', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center' 
            }}
          >
            <button 
              className="btn btn-primary rounded-circle" 
              onClick={() => submitToPR()}
              style={{ 
                width: '50px', 
                height: '50px', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
              }}
            >
               <i class="bi bi-clipboard2-plus-fill" style={{fontSize:"24px"}}></i>
            </button>
          </div>
        </div>
      )}

      </main>

      {/* Modal Show Add Supplier*/}
      <Modal
        show={showModalAddSupplier}
        onHide={() => setModalShowAddSupplier(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "14px" }}>{itemName}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12 mb-2">
              <label htmlFor="supplier" className="form-label">
                Supplier
              </label>
              <Select
                options={supplierSelect}
                getOptionLabel={(supplierSelect) => `${supplierSelect.label}`}
                id="supplier"
                onChange={(e) => onSelectSupplier(e)}
              />
            </div>

            <div className="col-8 mb-2">
              <label htmlFor="price" className="form-label">
                Price
              </label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="price"
                onChange={(e) => setPriceSupplier(e.target.value)}
              />
            </div>

            <div className="col-4 mb-2">
              <label htmlFor="tax" className="form-label">
                Tax
              </label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="tax"
                onChange={(e) => setTaxSupplier(e.target.value)}
              />
            </div>

            <div className="col-12 mb-2">
              <label htmlFor="total" className="form-label">
                Total
              </label>
              <input
                type="number"
                disabled
                value={
                  priceSupplier * QtyItem +
                  (taxSupplier / 100) * priceSupplier * QtyItem
                }
                className="form-control form-control-sm"
                id="total"
              />
            </div>

            <div className="col-12 mb-2">
              <label htmlFor="remark" className="form-label">
                Note / Remark{" "}
              </label>
              <textarea
                className="form-control form-control-sm"
                placeholder="Remark"
                id="remark"
                style={{ height: "100px" }}
                onChange={(e) => setRemarkSupplier(e.target.value)}
              ></textarea>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-warning"
            style={{ fontSize: "12px" }}
            onClick={() => submitSupplier()}
          >
            {submitting ? "Submitting" : "Submit"}
          </button>
          <button
            className="btn btn-danger"
            style={{ fontSize: "12px" }}
            onClick={() => setModalShowAddSupplier(false)}
          >
            Close{" "}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal Show Image Item Rev221024*/}
      <Modal show={modalShowImage} onHide={() => setModalShowImage(false)} centered size="md">
        <Modal.Body>
          <div className="text-center mb-3">
            <img 
              src={detailItem.img_item} 
              alt={detailItem.nama_item} 
              style={{ maxWidth: "100%",padding:"5%", maxHeight: "300px", objectFit: "contain" }} 
            />
          </div>

          <div className="info-section">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="fw-bold">Item:</span>
              <span>{detailItem.nama_item}</span>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="fw-bold">Group:</span>
              <span>{detailItem.group_item}</span>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="fw-bold">Minimum Order:</span>
              <span>{detailItem.min_order} {detailItem.unit}</span>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-2">
              <span className="fw-bold">Estimate Lead Time:</span>
              <span>{detailItem.lead_time} / Day</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      {/* Modal Show Edit Item */}
      <Modal show={modalShowEdit} onHide={() => setModalShowEdit(false)}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div className="col-md-12">
            <label htmlFor="item_id" className="form-label"> Item</label>
            <Select
              id="item_id"
              disabled
              defaultValue={detailItemPP.item_id}
              placeholder={detailItemPP.nama_item}
            />
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="quantity" className="form-label">
              Quantity
            </label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="quantity"
              disabled
              defaultValue={detailItemPP.quantity}
              onChange={(e) =>
                setDetailItemPP({
                  ...detailItemPP,
                  ["quantity"]: e.target.value,
                })
              }
            />
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="market" className="form-label">
              URL Item
            </label>
            <input style={{ fontSize: "12px" }} type="text" className="form-control form-control-sm" id="market" defaultValue={detailItemPP.url_item}
              onChange={(e) =>
                setDetailItemPP({
                  ...detailItemPP,
                  ["url_item"]: e.target.value,
                })
              }
            />
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="floatingTextarea" className="form-label"> Description </label>
            <textarea
              className="form-control form-control-sm"
              placeholder="Description"
              defaultValue={detailItemPP.comment_item}
              style={{ height: "100px", fontSize: "12px" }}
              onChange={(e) =>
                setDetailItemPP({
                  ...detailItemPP,
                  ["comment_item"]: e.target.value,
                })
              }
            ></textarea>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Show Edit Supplier Rev221024*/}
      <Modal
        show={showModalEditSupplier}
        onHide={() => setShowModalEditSupplier(false)}
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12 mb-1">
              <div className="info-section">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <span className="fw-bold">Item: {selectedSupplier.item}</span>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-1">
                  <span className="fw-bold">Qty: {selectedSupplier.quantity}</span>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <label htmlFor="supplier" className="form-label">
                Supplier
              </label>
              <Select options={supplierSelect} getOptionLabel={(supplierSelect) => `${supplierSelect.label}`}
                id="supplier"
                defaultValue={{
                  label: selectedSupplier.supplier,
                  value: selectedSupplier.supplier,
                }}
                onChange={(e) => onSelectSelectedSupplier(e)}
              />
            </div>

            <div className="col-8 mb-2">
              <label htmlFor="price" className="form-label">
                Price
              </label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="price"
                defaultValue={selectedSupplier.price}
                onChange={(e) =>
                  setSelectedSupplier({
                    ...selectedSupplier,
                    price: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-4 mb-2">
              <label htmlFor="tax" className="form-label">Tax</label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="tax"
                defaultValue={selectedSupplier.tax}
                onChange={(e) =>
                  setSelectedSupplier({
                    ...selectedSupplier,
                    tax: e.target.value,
                  })
                }
              />
            </div>

            <div className="col-12 mb-2">
              <label htmlFor="total" className="form-label">Total</label>
              <input
                type="number"
                disabled
                value={
                  selectedSupplier.price * selectedSupplier.quantity +
                  (selectedSupplier.tax / 100) *
                    selectedSupplier.price *
                    selectedSupplier.quantity
                }
                className="form-control form-control-sm"
                id="total"
              />
            </div>

            <div className="col-12 mb-2">
              <label htmlFor="remark" className="form-label"> Note / Remark (required)</label>
              <textarea
                className="form-control form-control-sm"
                placeholder="Remark"
                id="remark"
                defaultValue={selectedSupplier.remark}
                rows={2}
                onChange={(e) =>setSelectedSupplier({...selectedSupplier,remark: e.target.value})}
              ></textarea>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-warning" style={{ fontSize: "12px" }} onClick={editSupplier}>
            {submitting ? "Loading" : "Submit"}
          </button>
          <button className="btn btn-danger" style={{ fontSize: "12px" }} onClick={() => setShowModalEditSupplier(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal Cetak PR */}
      <Modal show={modalShowPrintPR} onHide={() => setModalShowPrintPR(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px" }}>
            <i className="bi bi-file-earmark-post"></i> Print|{DETPPID}
            {SUPPID}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12 mt-3">
            <label htmlFor="po" className="form-label">
              Template{" "}
            </label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="po"
                name="template"
                onChange={(e) => setTemplate(e.target.value)}
                id="po"
              />
              <label className="form-check-label" htmlFor="po">
                PO
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="template"
                value="spk"
                onChange={(e) => setTemplate(e.target.value)}
                id="spk"
              />
              <label className="form-check-label" htmlFor="spk">
                SPK
              </label>
            </div>
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="item_received" className="form-label">
              PO / SPK Valid
            </label>
            <input
              type="date"
              className="form-control form-control-sm"
              id="item_received"
              onChange={(e) => setPOValid(e.target.value)}
            />
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="item_received" className="form-label">
              Exp Received{" "}
            </label>
            <input
              type="date"
              className="form-control form-control-sm"
              id="item_received"
              placeholder={headerPP.date_need}
              onChange={(e) => setExReceived(e.target.value)}
            />
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="remarkpo" className="form-label">
              Remark{" "}
            </label>
            <textarea
              className="form-control form-control-sm"
              id="remarkpo"
              onChange={(e) => setCommentPO(e.target.value)}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            style={{ fontSize: "12px" }}
            className="btn btn-warning"
            onClick={() => doPrint()}
          >
            Generate
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal Upload PO */}
      <Modal
        show={ModalShowUploadPO}
        onHide={() => setModalShowUploadPO(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "16px" }}>
            <i className="bi bi-file-earmark-post"></i> Upload & Send Purchase
            Order
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="col-md-12 mt-0">
            <label htmlFor="template" className="form-label">
              Template
            </label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                s
                value="po"
                name="template"
                onChange={(e) => setTemplate(e.target.value)}
                id="po"
              />
              <label className="form-check-label" htmlFor="po">
                PO
              </label>
            </div>

            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="template"
                value="spk"
                onChange={(e) => setTemplate(e.target.value)}
                id="spk"
              />
              <label className="form-check-label" htmlFor="spk">
                SPK
              </label>
            </div>
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="emailSupplier" className="form-label">
              Email Supplier
            </label>
            <input
              type="email"
              className="form-control form-control-sm"
              id="emailSupplier"
              onChange={(e) => setEmailSupplier(e.target.value)}
            />
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="phoneSupplier" className="form-label">
              *Phone Supplier
            </label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="phoneSupplier"
              placeholder="811 XXX XXX"
              onChange={(e) => setPhoneSupplier(e.target.value)}
            />
          </div>

          <div className="col-md-12 mt-2">
            <label htmlFor="filePO" className="form-label">
              *File PO
            </label>
            <input
              type="file"
              className="form-control form-control-sm"
              id="filePO"
              //onChange={(e) => uploadPOFile(e)}
              onChange={uploadPOFileNew}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            style={{ fontSize: "12px" }}
            className="btn btn-warning"
            onClick={() => doUploadPO()}
          >
            {uploading ? "Uploading.." : "Upload & Send"}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal cancel and sendback PP */}
      <Modal show={showModalEditPP} onHide={() => setShowModalEditPP(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "14px" }}>
            Return PP Back to User
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12 mb-1">
              <label htmlFor="item" className="form-label">
                NO. PP
              </label>

              <input
                type="text"
                className="form-control form-control-sm"
                id="nopp"
                value={editedPP.nomer}
                disabled
              />
            </div>

            <div className="col-12 mb-1">
              <label htmlFor="item" className="form-label">
                PP Remark
              </label>

              <input
                type="text"
                className="form-control form-control-sm"
                id="nopp"
                value={editedPP.remark}
                disabled
              />
            </div>

            <div className="col-12 mb-2">
              <label htmlFor="remark" className="form-label">
                Purchasing Remark (required){" "}
              </label>
              <textarea
                className="form-control form-control-sm"
                placeholder="Add note to why the Purchase Proposal is sent back to user"
                id="remark"
                defaultValue={editedPP.purchasing_remark}
                style={{ height: "100px" }}
                onChange={(e) =>
                  setEditedPP({
                    ...editedPP,
                    purchasing_remark: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-warning"
            style={{ fontSize: "12px" }}
            onClick={returnPPUser}
          >
            {submitting ? "Submitting" : "Submit"}
          </button>
          <button
            className="btn btn-danger"
            style={{ fontSize: "12px" }}
            onClick={() => setShowModalEditPP(false)}
          >
            Close{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Detail;
