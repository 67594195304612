import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { getToken } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import _ from "lodash";

function Supplier() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const FileDownload = require("js-file-download");
  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;
  const [loading, setLoading] = useState(true);

  // Var
  const [listSupplier, setListSupplier] = useState([]);

  const downloadPurchaseProposalWaiting = () => {
    axios
      .get(
        `/api/generate/excel/report/waiting/proposal/${hcode}/${udeptID}`,
        { responseType: "blob" },
        { headers: headers }
      )
      .then(function (response) {
        //   console.log(response.data);
        FileDownload(response.data, "purchase_proposal.xlsx");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      name: "Company Name",
      wrap: true,
      cell: (row) => (
        <div>
          <b>
            {row.companytype}. {row.companyname}
          </b>
        </div>
      ),
    },
    {
      name: "Email",
      wrap: true,
      cell: (row) => row.email,
    },
    {
      name: "Phone",
      cell: (row) => row.telephoneno,
    },
    {
      name: " Bank ",
      cell: (row) => (
        <div>
          <b>
            {row.bankname}:{row.bankaccountno}
          </b>
        </div>
      ),
    },
    {
      name: "Product Category",
      wrap: true,
      cell: (row) => (
        <>
          <i>{row.productcategory}</i>
        </>
      ),
    },
    {
      name: "",
      width: "50px",
      cell: (row) => (
        <div onClick={() => history.push(`/detail/supplier/${row.idSupplier}`)}>
          <i class="bi bi-search"></i>
        </div>
      ),
    },
  ];

  // Search
  const [filtertext, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = listSupplier.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext.toLowerCase())
  );

  const handleClear = () => {
    if (filtertext) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const getSupplier = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/list/suppliers`, {
        headers: headers,
      });
      // console.log(response.data);
      setListSupplier(response.data);
    } catch (error) {
      console.log("Error getAPIListPPW: ", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedGetSupplier = _.debounce(getSupplier, 100);

  useEffect(() => {
    debouncedGetSupplier();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h6
            className="mb-0"
            style={{ fontWeight: "bold", marginLeft: "7px" }}
          >
            Supplier & Vendor
          </h6>
          <nav>
            <ol className="breadcrumb mt-1 mb-2 ms-2">
              <li
                className="breadcrumb-item"
                style={{ cursor: "pointer", fontSize: "13px" }}
                onClick={() => history.push("/dashboard")}
              >
                Home
              </li>
              <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                List
              </li>
              <li
                style={{ fontSize: "13px" }}
                className="breadcrumb-item active"
              >
                Supplier
              </li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row p-0 pb-2 mb-2 mt-3">
                    <div className="col-2 pe-0">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "12px" }}
                        onClick={() => debouncedGetSupplier()}
                      >
                        <i className="bi bi-arrow-clockwise"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "12px" }}
                        onClick={() => downloadPurchaseProposalWaiting()}
                      >
                        <i className="bi bi-printer"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "1%" }}
                        onClick={() => history.push("/add/supplier")}
                      >
                        <i className="bi bi-plus"></i>
                      </button>
                    </div>

                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setFilterText(e.target.value)}
                        onClear={() => handleClear}
                        filtertext={filtertext}
                      />
                    </div>
                  </div>

                  {loading ? (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                      <div style={{ marginTop: "10px" }}>Loading...</div>
                    </div>
                  ) : (
                    <div className="row">
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        fixedHeader
                        fixedHeaderScrollHeight="440px"
                        pagination
                        // expandableRows
                        // expandableRowsComponent={ExpandedComponent}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Supplier;
