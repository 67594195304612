import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../utils/axios";
//import Swal from "sweetalert2";
import Moment from "moment";
import { getToken } from "../../utils/Common";
//import { setUserSession } from "../../utils/Common";
import DataTable from "react-data-table-component";
import HEAD from "../../component/header";
import SIDE from "../../component/navbar";
import _ from "lodash";

function Proposal() {
  const token = getToken();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  };
  const history = useHistory();
  const FileDownload = require("js-file-download");
  // Session
  const session = localStorage.getItem("user");
  const udeptID = JSON.parse(session).dept_id;
  const hcode = JSON.parse(session).hotel_id;

  // Var
  const [purchaseProposal, setPurchaseProposal] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [statusOrder, setStatusOrder] = useState();
  const [hotelSort, setHotelSort] = useState();
  const [loading, setLoading] = useState(true);

  const downloadPurchaseProposalWaiting = async () => {
    try {
      const response = await axios.get(
        `/api/generate/excel/report/waiting/proposal/${hcode}/${udeptID}`,
        { responseType: "blob", headers: headers }
      );
      FileDownload(response.data, "purchase_proposal.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getListHotelbyUser = async () => {
    try {
      const response = await axios.post(
        `/api/list/hotel/by-user`,
        { hcode },
        { headers: headers }
      );
      setHotelList(response.data);
    } catch (error) {
      console.log("Error API Hotel: ", error);
    }
  };

  ///////////////////////////////////////////////////////// Table Search Data ////////////////////////////////////////
  const columns = [
    {
      name: "PP No.",
      width: "200px",
      wrap: true,
      selector: (row) => row.nomer,
    },
    {
      name: "Origin",
      wrap: true,
      cell: (row) => (
        <div>
          <b>For Hotel</b> : {row.nm_hotel}
          <br />
          <b>From Department: </b> {row.nm_department}
        </div>
      ),
    },

    {
      name: "Created",
      cell: (row) => Moment(row.created_at).format("LLL"),
    },
    {
      name: " Need For",
      cell: (row) => Moment(row.date_need).format("LL"),
    },
    {
      name: "Status",
      wrap: true,
      cell: (row) => (
        <>
          <i>{row.status}</i>
        </>
      ),
    },
    {
      name: "",
      width: "50px",
      cell: (row) => (
        <div onClick={() => history.push(`/detail/proposal/${row.id_pp}`)}>
          <i class="bi bi-search"></i>
        </div>
      ),
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.stts_pp >= 1 && row.stts_pp <= 3,
      style: {
        backgroundColor: "rgba(80, 255, 71, 0.2)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.stts_pp >= 4 && row.stts_pp < 8,
      style: {
        backgroundColor: "rgba(80, 0, 255, 0.2)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    {
      when: (row) => row.stts_pp >= 9 && row.stts_pp <= 11,
      style: {
        backgroundColor: "rgba(248, 148, 6, 0.2)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const handleChange = ({ selectedRows }) => {
    console.log("Selected Rows: ", selectedRows);
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <>
        <table className="mt-2 table table-bordered table-sm table-hover">
          <thead>
            <tr className="table-active">
              <th scope="col">Item </th>
              <th scope="col">Qty </th>
              <th scope="col">Supplier </th>
            </tr>
          </thead>
          <tbody>
            {data.details.map((v, k) => (
              <tr>
                <td>{v.item}</td>
                <td>
                  {v.qty} {v.unit}
                </td>
                <td>{v.supp || "Not Choosen Yet"}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {data.po ? (
          <table className="table table-bordered table-sm table-hover">
            <thead>
              <tr className="table-success">
                <th scope="col">Print </th>
                <th scope="col">Sent </th>
                <th scope="col">PO File </th>
              </tr>
            </thead>
            <tbody>
              {data.po.map((v, k) => (
                <tr>
                  <td>{v.print || "Not Print yet"}</td>
                  <td>{v.$sent || "Not Sent yet"}</td>
                  <td>
                    {(
                      <a
                        href={v.filepo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download
                      </a>
                    ) || "Not Yet"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </>
    );
  };

  const handleClear = () => {
    if (filtertext) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  // Search
  const [filtertext, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = purchaseProposal.filter((search) =>
    Object.values(search)
      .join("")
      .toLowerCase()
      .includes(filtertext.toLowerCase())
  );
  ///////////////////////////////////////////////////////// END Table Search Data ////////////////////////////////////////

  const getPurchaseProposalWaiting = async (hotelSort) => {
    setLoading(true);

    try {
      if (hotelSort) {
        const hcode = hotelSort;
        const response = await axios.post(`/api/waiting/proposal`,{ hcode, udeptID, statusOrder },{ headers: headers });
        setPurchaseProposal(Object.values(response.data).flatMap((arr) => arr));
      } else {
        const response = await axios.post(`/api/waiting/proposal`,{ hcode, udeptID, statusOrder },{ headers: headers });
        console.log(response.data);
        setPurchaseProposal(Object.values(response.data).flatMap((arr) => arr));
      }
    } catch (error) {
      console.log("Error getAPIListPPW: ", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedGetPurchaseProposalWaiting = _.debounce(getPurchaseProposalWaiting,200);
  const debouncedGetListHotelbyUser = _.debounce(getListHotelbyUser, 200);

  useEffect(() => {
    debouncedGetPurchaseProposalWaiting();
    debouncedGetListHotelbyUser();
  }, []);

  return (
    <>
      <HEAD />
      <SIDE />

      <main id="main" className="main">
        <div className="pagetitle">
          <h6 className="mb-0" style={{ fontWeight: "bold", marginLeft: "7px" }}>Purchase Proposal</h6>
          <nav>
            <ol className="breadcrumb mt-1 mb-2 ms-2">
              <li
                className="breadcrumb-item"
                style={{ cursor: "pointer", fontSize: "13px" }}
                onClick={() => history.push("/dashboard")}
              >
                Home
              </li>
              <li style={{ fontSize: "13px" }} className="breadcrumb-item">
                Purchase Proposal
              </li>
              <li
                style={{ fontSize: "13px" }}
                className="breadcrumb-item active"
              >
                Waiting
              </li>
            </ol>
          </nav>
        </div>

        <section>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row p-0 pb-2 mb-2 mt-3">
                    <div className="col-1 pe-0">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{
                          marginRight: "12%",
                        }}
                        onClick={() => window.location.reload()}
                      >
                        <i className="bi bi-arrow-clockwise"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-warning"
                        style={{ marginRight: "1%" }}
                        onClick={() => downloadPurchaseProposalWaiting()}
                      >
                        <i className="bi bi-printer"></i>
                      </button>
                    </div>

                    <div className="col-4 ps-1 pe-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={(e) => setFilterText(e.target.value)}
                        onClear={() => handleClear}
                        filtertext={filtertext}
                      />
                    </div>

                    <div className="col-3">
                      <select
                        id="statusSelect"
                        className="form-select form-select-sm"
                        onChange={(e) => setStatusOrder(e.target.value)}
                      >
                        <option>Status</option>
                        <option value="">
                          --------Purchase Proposal-------
                        </option>
                        <option value="1">User Request</option>
                        <option value="2">HM Approved</option>
                        <option value="3">DOO Approved</option>
                        <option value="">
                          --------Purchase Request-------
                        </option>
                        <option value="5">Purchasing Manager Approved</option>
                        <option value="6">Chief Accounting Approved</option>
                        <option value="7">DOO Approved</option>
                        <option value="8">MD Approved</option>
                        <option value="9">Printed & Waiting DOF Sign</option>
                        <option value="10">P0 Sent</option>
                        <option value="11">Item Receive</option>
                      </select>
                    </div>

                    <div className="col-4 ps-1">
                      <select
                        id="hotelSelect"
                        className="form-select form-select-sm"
                        onChange={(e) =>
                          debouncedGetPurchaseProposalWaiting(e.target.value)
                        }
                      >
                        <option value={hcode}>Group By Hotel</option>
                        {hotelList.map((x, t) =>
                          x.map((v, k) => (
                            <option key={k} value={v.id_hotel}>
                              {v.code_hotel} - {v.nm_hotel}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  {loading ? (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{ minHeight: "100px" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                      </div>
                      <div style={{ marginTop: "10px" }}>Loading...</div>
                    </div>
                  ) : (
                    <div className="row">
                      <DataTable
                        columns={columns}
                        data={filteredItems}
                        selectableRows
                        expandableRows
                        onSelectedRowsChange={handleChange}
                        pagination
                        conditionalRowStyles={conditionalRowStyles}
                        expandableRowsComponent={ExpandedComponent}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Proposal;
